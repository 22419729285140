import { en } from "~/i18n/en";
import { pl } from "~/i18n/pl";

type MessagesSchema = typeof en;

export default defineI18nConfig(() => ({
    legacy: false,
    locale: "en",
    messages: {
        en,
        pl,
    } satisfies Record<string, MessagesSchema>,
}));
